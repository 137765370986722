<template>
  <div class="subscribe-home">
    <subscribe-message-home-page />
  </div>
</template>

<script>
  import SubscribeMessageHomePage from "@v@/components/pages/subscribeMessage/home";
  export default {
    name: "index",
    components: {  SubscribeMessageHomePage, },
    data(){
      return {
        formBusy: false,
      };
    },
  }
</script>

<style>
</style>
