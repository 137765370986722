<template>
  <div class="entrust-list">
    <b-overlay :show="formBusy">
      <b-row>
        <b-col sm="12" md="9">
          <portlet body-class="flex-column" body-fluid head-size="sm" :title="subscribeMessageStock.title">
            <template slot="body">
              <b-row>
                <b-col cols="6" v-for="keyword in template_keywords" :key="keyword.name">
                  <b-form-group id="input-group-title" :label="keyword.title" :label-for="`${keyword.name}`" :state="validateState(keyword.name)" :aria-describedby="`${keyword.name}-live-feedback`">
                    <b-form-input :id="keyword.name" v-model="formData[keyword.name]" :placeholder="`${keyword.title} نى كىرگۈزۈڭ `" />
                    <b-form-invalid-feedback :id="`${keyword.name}-live-feedback`">
                      {{ feedback[`${keyword.rule}_feedback`] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group id="input-group-path" label="بەت ئادېرسى" label-for="page" >
                    <b-form-input class="direction-rtl" id="page" v-model="formData.page" :placeholder="pagePlaceholder" />
                  </b-form-group>
                </b-col>
                <template v-if="belongs === 'official_account'">
                  <b-col md="2" sm="6">
                    <b-form-group id="input-group-jump_state" label="ئەپچاققا ئاتلامدۇ؟" label-for="jump_state">
                      <b-form-radio-group
                        id="content_model"
                        v-model="jump_state"
                        :options="yesOrNot"
                        @change="onChangeJumpState"
                        buttons />
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
              <b-row v-if="belongs === 'official_account' && jump_state">
                <b-col md="6" sm="6">
                  <b-form-group id="input-group-appid" label="ئەپچاق appid" label-for="appid" >
                    <b-input-group append="APPID">
                      <b-form-input class="direction-rtl" id="appid" v-model="miniprogram.appid" placeholder="ئەپچاق appid سىنى كىرگۈزۈڭ" />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group id="input-group-pagepath" label="ئەپچاق بەت ئادېرسى" label-for="pagepath" >
                    <b-input-group append="بەت ئادېرسى">
                      <b-form-input class="direction-rtl" id="pagepath" v-model="miniprogram.pagepath" placeholder="ئەپچاق بەت ئادېرسىنى كىرگۈزۈڭ" />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
            <template slot="foot">
              <b-button variant="primary" @click="onSubmit">يوللاش</b-button>
            </template>
          </portlet>
        </b-col>
        <b-col sm="12" md="3">
          <portlet class="direction-rtl" body-class="flex-column" body-fluid head-size="sm" :title="subscribeMessageStock.title">
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact" v-for="template_keyword in subscribeMessageStock.template_keywords">
                        <span class="kt-widget__label">{{ template_keyword.title }}: </span>
                        <span class="kt-widget__data direction-rtl">{{ formData[template_keyword.name] }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="foot">
              <div class="d-flex align-items-center justify-content-between">
                <span>查看详情</span>
                <i class="fa fa-angle-right"></i>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import subscribeMessageStockMixin from "@m@/subscribeMessage.stock.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { validationMixin } from "vuelidate";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import {thing_valid, number_valid, letter_valid, symbol_valid, characterstring_valid, time_valid, date_valid, amount_valid, phone_number_valid, car_number_valid, name_valid, phrase_valid} from "@c@/plugins/validations/subscribe_message.validate"
  import {thing_feedback, number_feedback, letter_feedback, symbol_feedback, characterstring_feedback, time_feedback, date_feedback, amount_feedback, phone_number_feedback, car_number_feedback, name_feedback, phrase_feedback} from "@c@/plugins/validations/subscribe_message.validate"
  export default {
    name: "index",
    props: {
      belongs: {
        type: String,
        default: 'miniprogram', // official_account, miniprogram
      }
    },
    components: { Error, Portlet },
    mixins: [ formBusyMixin, sweetAlertMixin, subscribeMessageStockMixin, validationMixin, validateStateMixin ],
    computed: {
      modelId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
      pagePlaceholder(){
        if( this.belongs === 'miniprogram' )
          return 'ئەپچاق بەت ئادېرسىنى كىرگۈزۈڭ';
        return 'تور بەت ئادېرسىنى كىرگۈزۈڭ'
      }
    },
    validations(){
      let validates = {};
      let keywords = this.template_keywords || [];
      keywords.forEach((item, index)=>{
        let validatorName = item.rule + '_valid';
        if ( validatorName === 'thing_valid' ){
          validates[item.name] = {thing_valid};
        }else if(validatorName === 'number_valid'){
          validates[item.name] = {number_valid};
        }else if(validatorName === 'letter_valid'){
          validates[item.name] = {letter_valid};
        }else if(validatorName === 'symbol_valid'){
          validates[item.name] = {symbol_valid};
        }else if(validatorName === 'characterstring_valid'){
          validates[item.name] = {characterstring_valid};
        }else if(validatorName === 'time_valid'){
          validates[item.name] = {time_valid};
        }else if(validatorName === 'date_valid'){
          validates[item.name] = {date_valid};
        }else if(validatorName === 'amount_valid'){
          validates[item.name] = {amount_valid};
        }else if(validatorName === 'phone_number_valid'){
          validates[item.name] = {phone_number_valid};
        }else if(validatorName === 'car_number_valid'){
          validates[item.name] = {car_number_valid};
        }else if(validatorName === 'name_valid'){
          validates[item.name] = {name_valid};
        }else if(validatorName === 'phrase_valid'){
          validates[item.name] = {phrase_valid};
        }
      });
      return {
        formData: validates
      }
    },
    created() {
      this.getSubscribeMessageStock(this.modelId)
        .then(response=>{
          let { data } = response;
          let {template_keywords: keywords = []} = data;
          this.formatTemplateKeywords(keywords);
          this.template_keywords = keywords;
          // console.log(template_keywords);
        })
    },
    data(){
      return {
        formBusy: false,
        jump_state: false, // 是否跳转到小程序的开关
        formData: {
          page: null,
        },
        miniprogram: {
          appid: null,
          pagepath: null
        },
        template_keywords: [],
        feedback: {
          thing_feedback,
          number_feedback,
          letter_feedback,
          symbol_feedback,
          characterstring_feedback,
          time_feedback,
          date_feedback,
          amount_feedback,
          phone_number_feedback,
          car_number_feedback,
          name_feedback,
          phrase_feedback
        },
        yesOrNot: [
          { text: 'شۇنداق', value: true },
          { text: 'ياق', value: false },
        ],
      };
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى توغرا تولدۇرۇڭ'});
        }
        // 如果是公众号订阅消息，那就进行处理
        if( this.belongs === 'official_account' && this.jump_state ){
          let { appid, pagepath = '/pages/index/index' } = this.miniprogram;
          if( appid ){
            this.$set(this.formData, 'miniprogram', {appid, pagepath});
          }else{
            this.$delete(this.formData, 'miniprogram');
          }
        }
        this.sendSubscribeMessage(this.modelId, this.formData)
          .then(()=>{
            let { belongs } = this;
            let routeName = belongs === 'miniprogram' ? 'subscribe_message.history' : 'h5.subscribe_message.history';
            this.$router.replace({name: routeName, params: {id: this.modelId}});
          })
      },
      formatTemplateKeywords(keys = []){
        keys.forEach( (item, index)=> {
          let { name } = item;
          this.$set(this.formData, name, null);
        })
      },
      onChangeJumpState(e){}
    }
  }
</script>

<style>
</style>
