<template>
  <div class="menu-page">
    <error :errors="errorMessage" />
    <b-overlay :show="menuBusy">
      <menu-form @creating="handleCreating" @success="handleSuccess" @fail="handleFail" @loading="handleLoading" @loaded="handleLoaded" />
    </b-overlay>
  </div>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import MenuForm from "@v@/components/forms/MenuForm";
  import sweetAlert from "@m@/common/sweetAlert.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    mixins: [ sweetAlert, formBusyMixin ],
    components: { Error, Portlet, MenuForm },
    created() {},
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({ title: "سالون مەركىزى" });
      BreadCrumbs.addBreadCrumb({title: "تىزىملىك باشقۇرۇش", route: {name: 'h5.menus.home'}});
    },
    data(){
      return {
        menuBusy: false,
        errorMessage: null
      };
    },
    methods: {
      handleCreating(){
        this.menuBusy = true;
        this.errorMessage = null;
      },
      handleFail(e){
        this.menuBusy = false;
        let { data } = e.response;
        this.errorMessage = data.message;
      },
      handleSuccess(){
        this.menuBusy = false;
        this.success({ message: `تىزىملىك قۇرۇش مۇۋاپىقىەتلىك بولدى!` });
      },
      handleLoading(){
        this.menuBusy = true;
        this.errorMessage = null;
      },
      handleLoaded(){
        this.menuBusy = false;
      },
    }
  }
</script>
