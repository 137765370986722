import { GetAction, PutAction } from "@/store/actionHelper";

export default {
  computed: {},
  methods: {
    updateMenu(formData){
      return PutAction( `manager/wechat_official_account/menu`, formData, null, null, { credentials: true } );
    },
    getMenus(){
      return GetAction( `manager/wechat_official_account/menu`, '', null, null, { credentials: true } );
    },
  }
}
