<template>
  <div class="send">
    <subscribe-message-form />
  </div>
</template>

<script>
  import SubscribeMessageForm from "@v@/components/forms/SubscribeMessageForm";
  import Error from "@v@/partials/widgets/Error";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    components: { Error, SubscribeMessageForm },
    mixins: [ formBusyMixin ],
    computed: {},
    created() {},
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش', route: {name: 'subscribe_message.home'}});
      BreadCrumbs.addBreadCrumb({ title: "ئۇچۇر يوللاش" });
    },
    data(){
      return {}
    },
    methods: {}
  }
</script>

<style>
</style>
