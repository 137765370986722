import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_SUBSCRIBE_MESSAGE_STOCK,
  GET_SUBSCRIBE_MESSAGE_STOCKS,
  UPDATE_SUBSCRIBE_MESSAGE_STOCKS,
  SEND_SUBSCRIBE_MESSAGE,
  GET_SUBSCRIBE_MESSAGE_HISTORY,
} from "@/store/modules/subscribeMessage.stock.module";

export default {
  computed: {
    ...mapGetters(['subscribeMessageStock', 'subscribeMessageStocks', 'subscribeMessageStockError', 'subscribeMessageHistories', 'subscribeMessageHistoriesMeta'])
  },
  methods: {
    getSubscribeMessageStock(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_SUBSCRIBE_MESSAGE_STOCK, id),
      actions
      );
    },
    getSubscribeMessageStocks(belongs = 'mp', actions = {
      begin: ()=>{ this.showBusy('formBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('formBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_SUBSCRIBE_MESSAGE_STOCKS, belongs),
        actions
      );
    },
    updateSubscribeMessageStock(belongs = 'mp', actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_SUBSCRIBE_MESSAGE_STOCKS, belongs),
      actions
      );
    },
    sendSubscribeMessage(id, formData, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(SEND_SUBSCRIBE_MESSAGE, {id, formData}),
      actions
      );
    },
    getSubscribeMessageHistory(id, page = 1, actions = {
      begin: ()=>{ this.showBusy('formBusy'); },
      after: (response, state)=>{ this.hideBusy('formBusy'); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_SUBSCRIBE_MESSAGE_HISTORY, { id, page }),
      actions
      );
    },
  }
}
